import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
//import  axios from 'axios';

//pages and components
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup';
import HistoryBot from './pages/HistoryBot';
import DiseasePage from './pages/Diseases';
import PracticeQuestions from './pages/PracticeQuestions';
import MyStatistics from './pages/MyStatistics';

function App() {
  const { user } = useAuthContext();
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route
              path="/"
              element={user ? <Home />: <Navigate to="/login" />}
            />
            <Route
              path="/historyBot"
              element={user ? <HistoryBot />: <Navigate to="/login" />}
            />

            <Route
              path="/diseases"
              element={user ? <DiseasePage />: <Navigate to="/login" />}
            />
            <Route
              path="/practiceQuestions"
              element={user ? <PracticeQuestions />: <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={!user ? <Login />: <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={! user ? <Signup />: <Navigate to="/" />}
            />
            <Route
              path="/myStatistics"
              element={user ? <MyStatistics />: <Navigate to="/login" />}
            />
          </Routes>
        </div>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
