import { useState, useRef } from "react";

export const useDiseaseSubmit = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [historyBotRunning, setHistoryBotRunning] = useState(null);
    const terminatehistoryRunningnowRef = useRef(false);

    const terminatehistoryRunning = () => {
        console.log("terminatehistoryRunning is called");
        terminatehistoryRunningnowRef.current = true;
        console.log("terminatehistoryRunningnow after button press is: " + terminatehistoryRunningnowRef.current);
    }

    const whenHistoryTerminated = () => {
        if (terminatehistoryRunningnowRef.current) {
            console.log("terminatehistoryRunningnow is true in function");
            setHistoryBotRunning(false);
            setIsLoading(false);
            setError(null);
            terminatehistoryRunningnowRef.current = false;
            return;
        }
    }

    const speechToText = async () => {
        setIsLoading(true);
        setHistoryBotRunning(true);
        setError(null);

        try {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            if (!SpeechRecognition) {
                throw new Error("Speech recognition not supported in this browser.");
            }
            const recognition = new SpeechRecognition();
            recognition.continuous = false;
            recognition.interimResults = false;
            recognition.lang = "en-US";

            return new Promise((resolve, reject) => {
                let timeoutId;

                recognition.onresult = (event) => {
                    clearTimeout(timeoutId);
                    const transcript = event.results[0][0].transcript;
                    console.log("transcript is: " + transcript);
                    resolve(transcript);
                };

                recognition.onerror = (event) => {
                    clearTimeout(timeoutId);
                    reject(event.error);
                };

                recognition.onnomatch = () => {
                    clearTimeout(timeoutId);
                    resolve("--timeout--");
                };

                recognition.start();

                timeoutId = setTimeout(() => {
                    recognition.stop();
                    resolve("--timeout--");
                }, 10000);
            });
        } catch (error) {
            console.error("Speech recognition error:", error);
            throw error;
        }
    };

    const diseaseSubmit = async (disease) => {
        setHistoryBotRunning(true);
        setIsLoading(true);
        setError(null);
        terminatehistoryRunningnowRef.current = false;

        const historyConversation = document.querySelector('.historyConversation');
        historyConversation.innerHTML = "";

        console.log("disease passing through useHistorybot is: " + disease);

        const initialMessage = "I want you to pretend to be a patient with " + disease + ". I will be the doctor. I will ask you questions about your condition and you will answer them as if you are the patient, come up with a name and age befitting of a person with " + disease + ". reply OK if you understand";
        var messages = [{role: "user", content: initialMessage},
                        {role: "assistant", content: "OK"}];

        var newUserMessage = null;

        while (newUserMessage !== "Stop") {
            console.log("terminatehistoryRunningnow is: " + terminatehistoryRunningnowRef.current);
            if (terminatehistoryRunningnowRef.current) {
                console.log("terminatehistoryRunningnow is true");
                setHistoryBotRunning(false);
                setIsLoading(false);
                setError(null);
                terminatehistoryRunningnowRef.current = false;
                console.log("exiting before speech to text");
                return;
            }

            let newUserMessage = await speechToText();
            if (terminatehistoryRunningnowRef.current) {
                console.log("terminatehistoryRunningnow is true");
                setHistoryBotRunning(false);
                setIsLoading(false);
                setError(null);
                terminatehistoryRunningnowRef.current = false;
                return;
            }

            while (newUserMessage === "--timeout--") {
                console.log("Output is --timeout--");
                console.log("terminatehistoryRunningnow is: " + terminatehistoryRunningnowRef.current);
                if (terminatehistoryRunningnowRef.current) {
                    console.log("terminatehistoryRunningnow is true");
                    setHistoryBotRunning(false);
                    setIsLoading(false);
                    setError(null);
                    terminatehistoryRunningnowRef.current = false;
                    return;
                }
                newUserMessage = await speechToText();
            }

            const historyConversation = document.querySelector('.historyConversation');
            const listItem = document.createElement('li');
            listItem.textContent = newUserMessage;
            listItem.classList.add('userHistoryMessage');
            historyConversation.appendChild(listItem);

            messages.push({role: "user", content: newUserMessage});

            const response = await fetch("/api/historybotRoutes/find-complexity", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ messages }),
            });
            const json = await response.json();
            console.log("json in response is: " + json);
            console.log("successful disease response");
            if (!response.ok) {
                console.log("error in diseaseSubmit");
                setError(json.error);
                setIsLoading(false);
                return;
            }

            if (response.ok) {
                localStorage.setItem("disease", JSON.stringify(json.data.content));
                console.log("json.data.content is: " + json.data.content);

                const listItem = document.createElement('li');
                listItem.textContent = json.data.content;
                listItem.classList.add('botHistoryMessage');
                historyConversation.appendChild(listItem);

                await new Promise((resolve) => {
                    const utterance = new SpeechSynthesisUtterance(json.data.content);
                    speechSynthesis.speak(utterance);
                    utterance.onend = resolve;
                });

                messages.push({ role: "assistant", content: json.data.content });
                setIsLoading(false);
                localStorage.setItem("messages", messages);
            }
        }
    };

    return { error, isLoading, diseaseSubmit, speechToText, historyBotRunning, terminatehistoryRunning };
};