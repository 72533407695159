//allows all components to access the workouts data context

import {createContext, useReducer} from 'react';

export const DiseasesContext = createContext();

export const diseaseReducer = (state, action) => {
    switch(action.type){
        case 'Get_Disease':
            return {
                collections: action.payload
            }
        
        default:
            return state
    }
}

export const DiseasesContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(diseaseReducer, {
        collections: null
    })
    return (
        <DiseasesContext.Provider value={{...state, dispatch}}>
            { children }
        </DiseasesContext.Provider>
    )
}