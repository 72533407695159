import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import React, { useRef, useState } from 'react';

const Navbar = () => {
    const navbarRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const { logout } = useLogout();
    const { user } = useAuthContext();

    const handleClick = () => {
        logout();
    }

    const getNavbarHeight = () => {
        return navbarRef.current ? navbarRef.current.offsetHeight : 0;
    };
    Navbar.getNavbarHeight = getNavbarHeight;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    return(
        <header ref={navbarRef}>
            <div className="container">
                <Link to="/">
                    <h1>History Bot</h1>
                </Link>
                <button onClick={toggleMenu} className="hamburger-menu">☰</button>
                <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                    {user && (
                    <div  className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
                        <Link to="/historyBot" className="nav-link">historyBot</Link>
                        <Link to="/diseases" className="nav-link">diseases</Link>
                        <Link to="/practiceQuestions" className="nav-link">practice questions</Link>
                        <Link to="/myStatistics" className="nav-link">my statistics</Link>
                        <span>{user.email}</span>
                        <button onClick={handleClick}>Logout</button>
                    </div>
                    )}
                    {!user && (
                        <div  className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
                            <Link to="/login">Login</Link>
                            <Link to="/signup">Signup</Link>
                        </div>
                    )}
                </nav>
            </div>
        </header>
    )
}

export default Navbar;