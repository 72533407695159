import React, { useState, useCallback } from "react";
import { useLogin } from "../hooks/useLogin";
import { GoogleLogin } from "@react-oauth/google";


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { login, googleLogin, error, isLoading } = useLogin();


    

    const handleSubmit = async (e) => {
        e.preventDefault(); // prevents page from refreshing
        await login(email, password);
    };

    
    const googleSuccess = async (res) => {
        console.log(res);
        const credential= res?.credential;
        console.log("credential: " + credential);

        try {
            //dispatch({ type: "LOGINGOOGLE", payload: { credential }})
            console.log("credential: " + credential);

            await googleLogin(credential);
        } catch (error) {
            console.log(error);
        }
    }

    const googleFailure = (error) => {
        console.log(error);
        console.log("Google Sign In was unsuccessful. Try again later");
    }

    return (
        <form className="login" onSubmit={handleSubmit}>
            
            <h3> Log in</h3>

            <label>Email:</label>
            <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />
            <label>Password:</label>
            <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />

            <div className="login_mechanisms">
                <button disabled={isLoading}>Log in</button>
                {error && <div className="error">{error}</div>}
            </div>
            <GoogleLogin onSuccess={googleSuccess} onError={googleFailure} />
        </form>
    );
};

export default Login;