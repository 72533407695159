import React, { useState, useEffect, useRef } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";




const MyStatistics = () => {
    const { user } = useAuthContext();
    const [correctProportion, setCorrectProportion] = useState(0);
    const [recentCorrectProportion, setRecentCorrectProportion] = useState(0);

    const [incorrectProportion, setIncorrectProportion] = useState(0);
    const correctBarRef = useRef(null);
    const incorrectBarRef = useRef(null);
    const recentCorrectBarRef = useRef(null);
    const recentIncorrectBarRef = useRef(null);



    

    


    useEffect(() => {
        const fetchUserQuestions = async () => {
            const response = await fetch('/api/questions/getUserQuestions', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // assuming user.token is the user id
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const userQuestions = await response.json();
            const total = userQuestions.length;
            const correct = userQuestions.filter(question => question.correct).length;
            const incorrect = total - correct;

            console.log(correct)
            console.log(total)
            setCorrectProportion(correct / total);
            setIncorrectProportion(incorrect / total);

            // Calculate the proportion of correctly answered questions in the last 20 questions
            const recentQuestions = userQuestions.slice(-20);
            const recentTotal = recentQuestions.length;
            const recentCorrect = recentQuestions.filter(question => question.correct).length;
            setRecentCorrectProportion(recentCorrect / recentTotal);

            if (response.ok) {
                console.log('response successful');
            } else {
                console.error('Error:');
            }
        };
        fetchUserQuestions();
    }, []);

    useEffect(() => {
        if (correctBarRef.current) {
            correctBarRef.current.style.transition = 'width 2s';
            correctBarRef.current.style.width = `${correctProportion * 100}%`;
        }
        if (incorrectBarRef.current) {
            incorrectBarRef.current.style.transition = 'width 2s';
            incorrectBarRef.current.style.width = `${(1 - correctProportion) * 100}%`;
        }
        if (recentCorrectBarRef.current) {
            recentCorrectBarRef.current.style.transition = 'width 2s';
            recentCorrectBarRef.current.style.width = `${recentCorrectProportion * 100}%`;
        }
        if (recentIncorrectBarRef.current) {
            recentIncorrectBarRef.current.style.transition = 'width 2s';
            recentIncorrectBarRef.current.style.width = `${(1 - recentCorrectProportion) * 100}%`;
        }
    }, [correctProportion, recentCorrectProportion]);





    return (
        <div>
            <h1>My Statistics</h1>
            <div className="questionPannel">
                <div>
                    <p>Average correct answers: <strong>{(correctProportion * 100).toFixed(2)}%</strong></p>
                    <div style={{ display: 'flex', width: '100%', height: '20px' }}>
                        <div ref={correctBarRef} style={{ width: '0%', backgroundColor: '#4CAF50' }} />
                        <div ref={incorrectBarRef} style={{ width: '100%', backgroundColor: 'darkgrey' }} />
                    </div>                    
                </div>
                <div>
                    <p>Average correct answers in last 20 questions: <strong>{(recentCorrectProportion * 100).toFixed(2)}%</strong></p>
                    <div style={{ display: 'flex', width: '100%', height: '20px' }}>
                        <div ref={recentCorrectBarRef} style={{ width: '0%', backgroundColor: '#4CAF50' }} />
                        <div ref={recentIncorrectBarRef} style={{ width: '100%', backgroundColor: 'darkgrey' }} />
                    </div>                    
                </div>
            </div>
        </div>
    )
}

export default MyStatistics;
