import { useEffect } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from 'react-router-dom';


//components
import WorkoutDetails from "../components/WorkoutDetails";
import WorkoutForm from "../components/WorkoutForm";
import DeleteAllForm from "../components/testBTN";


const Home = () => {
    const {workouts, dispatch} = useWorkoutsContext()
    const { user } = useAuthContext();


    console.log("Home.js user: ", user)
    console.log("Home.js user token ", user.token)

    useEffect(() => {
        const fetchWorkouts = async () => {
            const response = await fetch('/api/workouts', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }); // not explicitly saying localhost:4000 because using proxy (only works in deelopment)
            const json = await response.json();

            if (response.ok) {
                console.log("successful fetch workouts response" + json)
                dispatch({ type: 'SET_WORKOUTS', payload: json })
            }
            if (!response.ok) {
                console.log("error fetching workouts")
            }

        }
        if (user) {
            fetchWorkouts();
        }

    }, [dispatch, user])//will only ever fire once (when 1st rendered)

    useEffect(() => {
        console.log(workouts);
      }, [workouts]);


      return (
        <div>
          <div className="Discription">  
            <p>Welcome to HistoryBot! The all in one study resource for med students. You can learn more about diseases, take a virtual history, and take unlimited practice questions. Pick one of the tabs to get started.</p>
          </div>
          <div className="PannelsContainer">
            <div className="Pannel">
                <Link to="/Diseases" className="Pannel">
                    <p>Generate study guides</p>
                </Link>
            </div>
            <div className="Pannel">
                <Link to="/HistoryBot" className="Pannel">
                    <p>Take a history</p>
                </Link>
            </div>
            <div className="Pannel">
                <Link to="/PracticeQuestions" className="Pannel">
                    <p>Answer practice questions</p>
                </Link>

            </div>
                
          </div>
        </div>
      )
    }

export default Home;