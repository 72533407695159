//allows all components to access the workouts data context

import {createContext, useReducer} from 'react';

export const CollectionsContext = createContext();

export const collectionReducer = (state, action) => {
    switch(action.type){
        case 'SET_COLLECTIONS':
            return {
                collections: action.payload
            }
        case 'CREATE_COLLECTION':
            return {
                collections: state.collections === null ? [action.payload] : [action.payload, ...state.collections]
            }
        case 'DELETE_COLLECTION':
            console.log('action payload id is: ', action.payload)
            console.log("deleting workout")
            console.log("state collection ids are: ", state.collections.map(c => c._id));
            return {
                collections: state.collections.filter((c) => c._id !== action.payload)
            }
        case 'DELETE_ALL_COLLECTIONS':
            return {
                collections: []
            }
        case 'UPDATE_COLLECTION':
            console.log("updating collection")
            console.log("state collection ids are: ", state.collections.map(c => c._id));
            console.log("action payload id is: ", action.payload._id)
            return {
                collections: state.collections.map((c) => c._id === action.payload._id ? action.payload : c)
            }
        default:
            return state
    }
}

export const CollectionsContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(collectionReducer, {
        collections: null
    })
    return (
        <CollectionsContext.Provider value={{...state, dispatch}}>
            { children }
        </CollectionsContext.Provider>
    )
}