import React from 'react';
import { useState } from 'react';
import { useDiseaseSubmit } from '../hooks/useHistorybot';

const HistoryBot = () => {

    const [disease, setDisease] = useState("");
    const [diseasebuttonhandeller, setdiseasebuttonhandeller] = useState("");
    const { error, isLoading, historyBotRunning, diseaseSubmit, terminatehistoryRunning } = useDiseaseSubmit();



    const handleSubmit = async (e) => {
        setdiseasebuttonhandeller("running")
        e.preventDefault();
        if (!historyBotRunning) {
            console.log("historyBot activated, disease is: " + disease);
            await diseaseSubmit(disease);
            console.log(localStorage.getItem("messages"));

        }
        if (historyBotRunning) {
            setdiseasebuttonhandeller("")
            console.log("historyBot is already running");
            terminatehistoryRunning();
        }

    }

    



    return(
        <div className="historyBotPage">
            <form className="historyBot" onSubmit={handleSubmit}>
                <h3> Take a history of:</h3>

                <label>Disease:</label>
                <input 
                    type="Disease"
                    onChange={(e) => setDisease(e.target.value)}
                    value={disease}
                    disabled={historyBotRunning} // Use isLoading instead of diseaseSubmit
                />

                <button className={disease ? "canSubmit" : "translucent"} disabled={!disease}>
                    {diseasebuttonhandeller ? "Stop History" : "Take History"}
                </button>
                </form>
            <div className="historyBotConversation">
                <h3>HistoryBot Response:</h3>

                    <ul className="historyConversation"></ul>
                    
                    
                
            </div>
        </div>
            
    )
}

export default HistoryBot;
