import React from 'react';
import { useState } from 'react';
import { useDiseaseSubmit } from '../hooks/useHistorybot';
import { useEffect } from 'react';
import { useCollectionsContext } from "../hooks/useCollectionsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import Navbar from '../components/Navbar';



const DiseasePage = () => {
    const navbarHeight = Navbar.getNavbarHeight();

    const {collections, dispatch} = useCollectionsContext()
    const { user } = useAuthContext();
    const [showInput, setShowInput] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [newDisease, setNewDisease] = useState("");
    const [showAddDisease, setShowAddDisease] = useState(false);
    const [selectedDisease, setSelectedDisease] = useState('Diseases');
    const [diseaseInfo, setDiseaseInfo] = useState(null);

    const [isSidebarVisible, setSidebarVisible] = useState(false);









    const [title, setTitle] = useState('');
    const [error, setError] = useState(null);

    const selectDiseaseHandler = async (disease) => {
        // Do something with the selected disease
        console.log(`Selected disease: ${disease}`);
        console.log("fetchign disease info")
        setSelectedDisease(disease);
    
        //e.preventDefault();
        if (!user) {
            setError('You must be logged in to fetch diseases');
            return;
        }
    
        try {
            console.log("trying to fetch disease info")
            const response = await fetch(`/api/diseases/get_disease`, {
                method: 'POST',
                body: JSON.stringify({ disease }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
            });
    
            const json = await response.json();
    
            if (response.ok) {
                setTitle('');
                setDiseaseInfo(json);
                setError(null);
                console.log('New disease selected:', json);
                // dispatch({ type: 'CREATE_COLLECTION', payload: json });
                setShowInput(false);
            } else {
                console.error('Error adding disease:', json.error);
                setError(json.error);
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error);
            setError('An unexpected error occurred while adding the disease.');
        }
    };

    const deleteDiseaseHandler = async (oldDisease, collectionId) => {
        console.log(`Deleting disease ${oldDisease} from collection ${collectionId}`);

        const disease = { collection_id: collectionId, name: oldDisease, method: 'delete' }
        console.log("disease is: " + oldDisease.name)
        console.log("collectionID is: " + oldDisease.collection_id)
        console.log("method is: " + oldDisease.method)
    
        const response = await fetch(`/api/diseases/${collectionId}`, {
            method: 'POST',
            body: JSON.stringify(disease),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })

        const json = await response.json()

        if (!response.ok) {
            console.log('response was not ok')
            console.error(`HTTP error! status: ${response.status}`);
            setError(json.error)
        }
        if (response.ok) {
            setNewDisease('')
            setError(null)
            console.log('json is', json)
            console.log('disease removed', json)
            dispatch({ type: 'UPDATE_COLLECTION', payload: json })
            setShowInput(false);
        }
    }

    const handleChange = (e) => {
        setTitle(e.target.value);
    }
    const handleDiseaseChange = (e) => {
        setNewDisease(e.target.value);
    }
    const yourButtonClickHandler = () => {
        console.log("Your button was clicked");
    }

    const addDiseaseHandler = async (e, newDisease, collectionId) => {
    e.preventDefault();
    console.log(`Adding disease ${newDisease} to collection ${collectionId}`);
    if (!user) {
        setError('You must be logged in to add a disease')
        return
    }
    const disease = { collection_id: collectionId, name: newDisease, method: 'append' }
    console.log("disease is: " + disease.name)
    console.log("collectionID is: " + disease.collection_id)
    console.log("method is: " + disease.method)

    const response = await fetch(`/api/diseases/${collectionId}`, {
        method: 'POST',
        body: JSON.stringify(disease),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        },
    })
    const json = await response.json()

    if (!response.ok) {
        console.log('response was not ok')
        console.error(`HTTP error! status: ${response.status}`);
        setError(json.error)
    }
    if (response.ok) {
        setNewDisease('')
        setError(null)
        console.log('json is', json)
        console.log('new collection added', json)
        dispatch({ type: 'UPDATE_COLLECTION', payload: json })
        setShowInput(false);
    }
}

    

    const deleteCollectionHandler = async (collectionId) => {
        const response = await fetch(`/api/diseases/${collectionId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })
    
        if (response.ok) {
            console.log('collection deleted')
            dispatch({ type: 'DELETE_COLLECTION', payload: collectionId })
        }
    }


    const NewCollectionhandeller = async (e) => {
        e.preventDefault();
        console.log("Add Collection button clicked");
        if (!user) {
            setError('You must be logged in to add a collection')
            return
        }
        const collection = { title }
        console.log("collection is: " +collection.title)

        const response = await fetch('/api/diseases', {
            method: 'POST',
            body: JSON.stringify(collection),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })

        const json = await response.json()

        if (!response.ok) {
            console.log('response was not ok')
            setError(json.error)
        }
        if (response.ok) {
            setTitle('')
            setError(null)
            console.log('new collection added', json)
            dispatch({ type: 'CREATE_COLLECTION', payload: json })
            setShowInput(false);
        }
    }

    useEffect(() => {
        const fetchCollections = async () => {
            const response = await fetch('/api/diseases', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }); // not explicitly saying localhost:4000 because using proxy (only works in deelopment)
            const json = await response.json();

            if (response.ok) {
                console.log("successful fetch collections response" + json)
                dispatch({ type: 'SET_COLLECTIONS', payload: json })
            }
            if (!response.ok) {
                console.log("error fetching collections")
            }

        }
        if (user) {
            fetchCollections();
        }

    }, [dispatch, user])//will only ever fire once (when 1st rendered)

    useEffect(() => {
        console.log(collections);
      }, [collections]);



    useEffect(() => {
        const navbar = document.querySelector('.container');
        const navbarHeight = navbar.offsetHeight;
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.top = `${navbarHeight}px`;
        sidebar.style.height = `calc(100% - ${navbarHeight}px)`;
    }, []);

    useEffect(() => {
        const sidebar = document.querySelector('.sidebar');
        const sidebarWidth = sidebar.offsetWidth;
        const diseaseField = document.querySelector('.mainDiseaseView');
        diseaseField.style.marginLeft = `${sidebarWidth}px`;
        sidebar.style.offsetWidth = `calc(100% - ${sidebarWidth}px)`;
    }, []);

    const [disease, setDisease] = useState("");
    const [diseasebuttonhandeller, setdiseasebuttonhandeller] = useState("");

    return (
        <div className="DiseasesPage">
            <div className="sidebar" style={{
                transform: isSidebarVisible ? 'translateX(0)' : 'translateX(-100%)', 
                transition: 'transform 0.3s ease-in-out',
                width: isSidebarVisible ? '200px' : '0', 
                overflow: 'hidden',
                zIndex: 2,
                position: 'fixed', 
                left: '0', top: '0', 
                height: '100vh', 
                padding: '20px', 


            }}>
                <h2>Collections</h2>
                {!showInput && <button className="sidebarButton" onClick={() => setShowInput(true)}>New Collection</button>}
                {showInput && (
                    <form onSubmit={NewCollectionhandeller}>
                        <input type="text" value={title} onChange={handleChange} />
                        <div className="buttonContainer">
                            <button type="submit" className="sidebarButton">Add</button>
                            <button type="button" className="sidebarButton" onClick={() => setShowInput(false)}>Cancel</button>
                        </div>
                    </form>
                )}
            
                    <ul>
                    {collections && collections.map(collection => (
                        <React.Fragment key={collection._id}>
                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                <a href="#" onClick={() => {
                                    setSelectedCollections(prev => {
                                        if (prev.includes(collection._id)) {
                                            return prev.filter(id => id !== collection._id);
                                        } else {
                                            return [...prev, collection._id];
                                        }
                                    });
                                }}>{collection.title}</a>
                                <button style={{ marginLeft: 'auto' }} onClick={() => deleteCollectionHandler(collection._id)}>Delete</button>
                            </li>
                            {selectedCollections.includes(collection._id) && (
                                <>
                                    {!showAddDisease && <button className="sidebarButton" onClick={() => setShowAddDisease(true)}>New Disease</button>}
                                    {showAddDisease && (
                                        <>
                                            <input type="text" value={newDisease} onChange={handleDiseaseChange} />
                                            <div className="buttonContainer">
                                                <button className="sidebarButton" onClick={(e) => addDiseaseHandler(e, newDisease, collection._id)}>Add</button>
                                                <button className="sidebarButton" onClick={() => setShowAddDisease(false)}>Cancel</button>
                                            </div>
                                        </>
                                    )}
                                        {collection.diseases.map((disease, index) => (
                                            <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    selectDiseaseHandler(disease);
                                                }}>{disease}</a>
                                                <a href="#" style={{ marginLeft: 'auto' }} onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteDiseaseHandler(disease, collection._id);
                                                }}>Delete</a>
                                            </li>
                                        ))}
                                </>
                            )}
                        </React.Fragment>
                    ))}
                    </ul>
            </div>
            <button className="sidebar_toggle"
                onClick={() => setSidebarVisible(!isSidebarVisible)} 
                style={{
                    position: 'absolute',
                    top: '50vh', // 50% of the viewport height
                    left: isSidebarVisible ? '210px' : '10px',
                    transition: 'left 0.3s ease-in-out',
                    zIndex: 3

                }}
            >
                {isSidebarVisible ? '«' : '»'} {/* Leftwards arrow when sidebar is visible, rightwards arrow otherwise */}
            </button>
            <div className="mainDiseaseView">
                <h2>{selectedDisease}</h2>
                <p>Click on a collection to view its diseases</p>



                <div>
                    {diseaseInfo && (
                        <div id="mainDiseaseView">
                            <h2>{diseaseInfo.title}</h2>
                            <p>{diseaseInfo.summary}</p>
                            <h3>Risk Factors</h3>
                            <ul>
                                {diseaseInfo.risk_factors.split('+').map((riskFactor, index) => (
                                    <li key={index}>{riskFactor.trim()}</li>
                                ))}
                            </ul>
                            <h3>Symptoms</h3>
                            <ul>
                                {diseaseInfo.symptoms.split('+').map((symptom, index) => (
                                    <li key={index}>{symptom.trim()}</li>
                                ))}
                            </ul>
                            <h3>Pathophysiology</h3>
                            {diseaseInfo.pathology.split('+').map((patho, index) => (
                                    <li key={index}>{patho.trim()}</li>
                                    ))}
                        </div>
                    )}
                    {/* rest of your render method */}
                </div>
                </div>



        </div>
    )
}

export default DiseasePage;
            



