import React, { useState, useEffect } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";
import stringSimilarity from 'string-similarity';
import Navbar from '../components/Navbar';



const PracticeQuestions = () => {
    const navbarHeight = Navbar.getNavbarHeight();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [system, setSystem] = useState('');
    const [science, setScience] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [error, setError] = useState(null);
    const { user } = useAuthContext();

    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [answer, setAnswer] = useState('');
    const [explanation, setExplanation] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [question_id, setQuestion_Id] = useState(null);

    const [randomSystem, setRandomSystem] = useState(false);
    const [randomScience, setRandomScience] = useState(false); 
    const [randomDifficulty, setRandomDifficulty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isSidebarVisible, setSidebarVisible] = useState(false);




    const handleSubmit = async () => {
        if (isSubmitted){
            submitParameters();
            return;
        } else {
            setIsSubmitted(true);

            const bestMatch = stringSimilarity.findBestMatch(answer, options.split('#').filter(option => option)).bestMatch.target;
            console.log("selected option is", selectedOption)
            console.log("best match is", bestMatch)
            const isCorrect = selectedOption === bestMatch;
            console.log("is correct is", isCorrect)
    
            // Send data to the backend
            const response = await fetch('/api/questions/submitAnswer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}` // assuming user.token is the user id
                },
                body: JSON.stringify({
                    question_id: question_id, // assuming id is the question id
                    is_correct: isCorrect,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            if (response.ok) {
                console.log('response successful');
            } else {
                console.error('Error:', data.error);
            }

        }

      


    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const submitParameters = async () => {
        setIsLoading(true);
        let finalSystem = system;
        console.log("initially, system is", system)
        if (randomSystem) {
            console.log("system is random")
            const options = ["musculoskeletal", "Respiratory", "Cardiovascular", "Renal", "Endocrine", "gastrointestinal", "Neurological", "Haematological", "Oncological"];
            finalSystem = options[Math.floor(Math.random() * options.length)];
            setSystem(finalSystem);
        }
        // Do something with the selected disease
        console.log("Selected system: " + finalSystem);

        let finalScience = science;
        console.log("initially, science is", science)
        if (randomScience) {
            console.log("science is random")
            const options = ["diagnosis", "treatment", "pathophysiology", "prognosis", "anatomy", "histology"];
            finalScience = options[Math.floor(Math.random() * options.length)];
            setScience(finalScience);
        }
        console.log("Selected science: " + finalScience);

        let finalDifficulty = difficulty;
        console.log("initially, difficulty is", difficulty)
        if (randomDifficulty) {
            console.log("difficulty is random")
            const options = ["easy", "medium", "hard", "neuaunced"];
            finalDifficulty = options[Math.floor(Math.random() * options.length)];
            setDifficulty(finalDifficulty);
        }
        console.log("Selected difficulty: " + finalDifficulty);

        console.log("fetchign question")
        
        //setSelectedDisease(disease);
    
        //e.preventDefault();
        if (!user) {
            setError('You must be logged in to ask questions');
            return;
        }
    
        try {
            console.log("trying to fetch a question")
            const response = await fetch(`/api/questions/`, {
                method: 'POST',
                body: JSON.stringify({ system, science, difficulty }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
            });
    
            const json = await response.json();
    
            if (response.ok) {
                console.log('New question:', json);
                // dispatch({ type: 'CREATE_COLLECTION', payload: json });
                //setShowInput(false);
                setIsLoading(false);
                setIsSubmitted(false);
                setQuestion(json.question);
                console.log('question is:', json.question);
                setOptions(json.options);
                setAnswer(json.correct_option);
                setExplanation(json.explanation);
                setQuestion_Id(json._id);
                console.log('question id is:', json._id);
               
            } else {
                console.error('Error adding disease:', json.error);
                //setError(json.error);
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error);
            //setError('An unexpected error occurred while adding the disease.');
        }
    };

    useEffect(() => {
        const navbar = document.querySelector('.container');
        const navbarHeight = navbar.offsetHeight;
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.top = `${navbarHeight}px`;
        sidebar.style.height = `calc(100% - ${navbarHeight}px)`;
    }, []);

    useEffect(() => {
        const sidebar = document.querySelector('.sidebar');
        const sidebarWidth = sidebar.offsetWidth;
        const diseaseField = document.querySelector('.questionPannel');
        diseaseField.style.marginLeft = `${sidebarWidth}px`;
        sidebar.style.offsetWidth = `calc(100% - ${sidebarWidth}px)`;
    }, []);

    return (
        
        <div style={{display: 'flex'}}>

            <div className="sidebar" style={{
                width: isSidebarVisible ? '200px' : '0', 
                position: 'fixed', 
                left: '0', top: '0', 
                height: '100vh', 
                padding: '20px', 
                transform: isSidebarVisible ? 'translateX(0)' : 'translateX(-100%)', 
                transition: 'transform 0.3s ease-in-out',
                overflow: 'hidden'

                }}>
            <select className="dropdown-menu" onChange={e => {
                let value = e.target.value;
                if (value === "Random") {
                    setRandomSystem(true);
                    const options = ["musculoskeletal", "Respiratory", "Cardiovascular", "Renal", "Endocrine", "gastrointestinal", "Neurological", "Haematological", "Oncological"];
                    value = options[Math.floor(Math.random() * options.length)];
                }
                else{
                    setRandomSystem(false);
                }
                setSystem(value);
                console.log(value)
            }}>
                <option value="">Select a system</option>
                <option value="musculoskeletal">musculoskeletal</option>
                <option value="Respiratory">Respiratory</option>
                <option value="Cardiovascular">Cardiovascular</option>
                <option value="Renal">Renal</option>
                <option value="Endocrine">Endocrine</option>
                <option value="gastrointestinal">gastrointestinal</option>
                <option value="Neurological">Neurological</option>
                <option value="Haematological">haematology</option>
                <option value="Oncological">oncology</option>
                <option value="Random">Random</option>
            </select>
            <select className="dropdown-menu" onChange={e => {
                let value = e.target.value;
                if (value === "Random") {
                    setRandomScience(true);
                    const options = ["diagnosis", "treatment", "pathophysiology", "prognosis"];
                    value = options[Math.floor(Math.random() * options.length)];
                }
                else{
                    setRandomScience(false);
                }
                setScience(value);
                console.log(value)
            }}>
                    <option value="">Select a science</option>
                    <option value="diagnosis">diagnosis</option>
                    <option value="treatment">treatment</option>
                    <option value="pathophysiology">pathophysiology</option>
                    <option value="prognosis">prognosis</option>
                    <option value="anatomy">anatomy</option>
                    <option value="histology">histology</option>
                    <option value="Random">Random</option>


                </select>
                <select className="dropdown-menu" onChange={e => {
                    let value = e.target.value;
                    if (value === "Random") {
                        setRandomDifficulty(true);
                        const options = ["easy", "medium", "hard", "neuaunced"];
                        value = options[Math.floor(Math.random() * options.length)];
                    }
                    else{
                        setRandomDifficulty(false);
                    }
                    setDifficulty(value);
                    console.log(value)
                }}>
                    <option value="">Select a difficulty</option>
                    <option value="an easy">easy</option>
                    <option value="a medium difficulty">medium</option>
                    <option value="a difficult">hard</option>
                    <option value="an incredibly esoteric">neuaunced</option>
                    <option value="Random">Random</option>


                </select>
                <button type="submit" onClick={submitParameters} className="submit-button">Submit</button>

            </div>
            <button className="sidebar_toggle"
                onClick={() => setSidebarVisible(!isSidebarVisible)} 
                style={{
                    position: 'absolute',
                    top: '50vh', // 50% of the viewport height
                    left: isSidebarVisible ? '210px' : '10px',
                    transition: 'left 0.3s ease-in-out',

                }}
            >
                {isSidebarVisible ? '«' : '»'} {/* Leftwards arrow when sidebar is visible, rightwards arrow otherwise */}
            </button>
            <div className='questionPannel' style={{flex: '1', opacity: !isLoading ? 1 : 0.5}}>
                <h1>Practice Questions</h1>
                <div id="question" className="question">{question.replace(/#*$/, '')}</div>
                <div id="options" style={{paddingLeft: '20px'}}>
                {typeof options === 'string' && options.split('#').filter(option => option).map((option, index) => {
                    const bestMatch = stringSimilarity.findBestMatch(answer, options.split('#').filter(option => option)).bestMatch.target;
                    return (
                        <div key={index} className="option" style={{backgroundColor: isSubmitted && option === bestMatch ? '#4CAF50' : option === selectedOption ? 'darkgray' : 'transparent', margin: '0px 0', padding: '0px 0'}}>                        
                            <input type="radio" id={`option${index}`} name="option" value={option} onChange={handleOptionChange} style={{display: 'none'}} disabled={isSubmitted} checked={option === selectedOption} />
                            <label htmlFor={`option${index}`} className="option-label" style={{color: isSubmitted && option === bestMatch ? 'white' : 'black'}}>
                                {option}
                            </label>
                        </div>
                    );
                })}
                </div>
                <button type="submit" onClick={handleSubmit} className="submit-button">
                    {isSubmitted ? "Next Question" : "Submit"}
                </button>
                {isSubmitted && (
                    <>
                        <div id="explanation" className="explanation">{explanation}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PracticeQuestions;
