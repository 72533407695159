import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch("/api/user/login",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        })
        const json = await response.json();
        console.log("successful signup response")

        if (!response.ok) {
            setError(json.error);
            setIsLoading(false);
            return;
        }
        if(response.ok){
            //save the user to local storage
            localStorage.setItem("user", JSON.stringify(json));
            localStorage.setItem("userType", "accountUser");

            //update auth context
            dispatch({type: "LOGIN", payload: json});

            setIsLoading(false);
        }
    }

    const googleLogin = async (credential) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch("/api/user/googleLogin",{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ credential }),
        })
        const json = await response.json();
        console.log("successful google login response")

        if (!response.ok) {
            setError(json.error);
            setIsLoading(false);
            return;
        }
        if(response.ok){
            //save the user to local storage
            localStorage.setItem("user", JSON.stringify(json));
            localStorage.setItem("userType", "googleUser");

            //update auth context
            dispatch({type: "LOGIN", payload: json});

            setIsLoading(false);
        }
    }

    return { error, isLoading, login, googleLogin };
}