import { CollectionsContext } from "../context/CollectionContext";
import { useContext } from "react";

export const useCollectionsContext = () => {
    const context = useContext(CollectionsContext);

    if (!context) {
        throw Error('useCollectionContext must be used within a CollectionContextProvider')
    }

    return context; //returns the state and dispatch
}