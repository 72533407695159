//allows all components to access the workouts data context

import {createContext, useReducer} from 'react';

export const WorkoutsContext = createContext();

export const workoutsReducer = (state, action) => {
    switch(action.type){
        case 'SET_WORKOUTS':
            return {
                workouts: action.payload
            }
        case 'CREATE_WORKOUT':
            return {
                workouts: state.workouts === null ? [action.payload] : [action.payload, ...state.workouts]
            }
        case 'DELETE_WORKOUT':
            console.log('action payload id is: ', action.payload)
            return {
                workouts: state.workouts.filter((w) => w._id !== action.payload._id)
            }
        case 'DELETE_ALL_WORKOUTS':
            return {
                workouts: []
            }
        default:
            return state
    }
}

export const WorkoutsContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(workoutsReducer, {
        workouts: null
    })
    return (
        <WorkoutsContext.Provider value={{...state, dispatch}}>
            { children }
        </WorkoutsContext.Provider>
    )
}