import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WorkoutsContextProvider } from './context/WorkoutContext'
import { CollectionsContextProvider } from './context/CollectionContext';
import { AuthContextProvider } from './context/AuthContext';
import { DiseasesContextProvider } from './context/DiseaseContext';

import { GoogleOAuthProvider } from '@react-oauth/google';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId= "952568787771-aqkpkiv54h5kc9hu09jq79gf3vhfklog.apps.googleusercontent.com">
      <AuthContextProvider>
        <CollectionsContextProvider>
        <DiseasesContextProvider>
          <WorkoutsContextProvider>
            <App />
          </WorkoutsContextProvider>
        </DiseasesContextProvider>
      </CollectionsContextProvider> 
      </AuthContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);


